<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <el-col :span="24">
        <el-select clearable v-model="queryInfo.route" @change="selectChange" placeholder="筛选关联路由">
          <el-option-group
            v-for="(group, index) in clientRouteList"
            :key="index"
            :label="group.label">
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-option-group>
        </el-select>
        <el-button class="tab-add-button" type="primary" @click="addClick">添加轮播图</el-button>
      </el-col>
    </el-row>

    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="slideshowList"
      v-bind="otherProps"
      :listCount="slideshowTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #imageSlot="scope">
        <el-image slot="error" :src="scope.row.image_path" >
          <i class="el-icon-picture-outline"></i>
        </el-image>
      </template>
      <template #operation="scope">
        <span @click="lookViewClick(scope.row)" style="margin-right: 10px;cursor: pointer;">页面关联管理</span>
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>
    <add-edit-slideshow-drawer :params="addEditInfo" @close="addEditDrawerClose"></add-edit-slideshow-drawer>
    <slideshow-route-dialog :params="slideshowRouteInfo" @refresh="slideshowRefresh" @close="slideshowRouteClose"></slideshow-route-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/slideshowConfig.js'
import { deleteSlideshow } from '@/api/slideshow/slideshow'
import addEditSlideshowDrawer from './cnps/add-edit-slideshow-drawer.vue'
import slideshowRouteDialog from './cnps/slideshow-route-dialog.vue'
import clientRouteList from '@/utils/clientRouteList'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      clientRouteList,
      queryInfo: {
        cur: 1,
        size: 20,
        route: '',
      },
      addEditInfo: {
        show: false,
        data: null,
      },
      slideshowRouteInfo: {
        show: false,
        slideshow_id: null,
        view: [],
      }
    }
  },
  components: {
    TableUi,
    addEditSlideshowDrawer,
    slideshowRouteDialog,
  },
  mounted() {
    this.getDataList()
  },
  computed: {
    ...mapState('slideshow', ['slideshowList', 'slideshowTotal'])
  },
  methods: {
    ...mapActions('slideshow', ['getSlideshowList']),
    getDataList () {
      this.getSlideshowList(this.queryInfo)
    },
    pageChange(ev) {
      this.queryInfo.cur = ev.cur
      this.queryInfo.size = ev.size
      this.getDataList()
    },
    selectChange() {
      this.queryInfo.cur = 1
      this.getDataList()
    },
    // 增加
    addClick() {
      this.addEditInfo.show = true
      this.addEditInfo.data = null;
    },
    // 编辑
    async editClick(row) {
      this.addEditInfo.data = {
        id: row.id,
        title_cn: row.title_cn,
        title_us: row.title_us,
        text_cn: row.text_cn || '',
        text_us: row.text_us || '',
        button_text_cn: row.button_text_cn || '',
        button_text_us: row.button_text_us || '',
        route: row.route || '',
        param: row.param || '',
        pagination_text_cn: row.pagination_text_cn || '',
        pagination_text_us: row.pagination_text_us || '',
        image_path: row.image_path
      }
      this.addEditInfo.show = true
    },
    // 关闭窗口
    addEditDrawerClose () {
      this.addEditInfo.show = false;
      this.addEditInfo.data = null;
      this.getDataList()
    },
    slideshowRouteClose () {
      this.slideshowRouteInfo.show = false;
      this.slideshowRouteInfo.view = [];
      this.slideshowRouteInfo.slideshow_id = null;
      this.getDataList()
    },
    // 查看轮播图绑定的路由页面
    async lookViewClick (row) {
      this.slideshowRouteInfo.view = row.view
      this.slideshowRouteInfo.slideshow_id = row.id
      this.slideshowRouteInfo.show = true;
    },
    async slideshowRefresh () {
      await this.getSlideshowList(this.queryInfo)
      const index = this.slideshowList.findIndex(v => v.id === this.slideshowRouteInfo.slideshow_id)
      if (index !== -1) {
        this.slideshowRouteInfo.view = this.slideshowList[index].view
      }
    },
    // 删除
    async deleteClick(id) {
      const result = await deleteSlideshow(id)
      if (result.success) {
        this.$message.success('删除成功')
        this.getDataList()
      }
    }
  },
  
}
</script>

<style lang="less" scoped>
  .tab-add-button {
    margin-left: 10px;
  }
  .tab-input {
    width: 260px;
  }
</style>