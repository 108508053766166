<template>
  <el-drawer :visible.sync="params.show" :before-close="close"  size="40%" :modal="false" :with-header="false" @open="openDrawer">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="140px">
      <el-form-item label="封面" prop="image_path">
        <image-upload v-model="ruleForm.image_path"></image-upload>
      </el-form-item>

      <el-form-item label="标题(中文)" prop="title_cn">
        <el-input v-model="ruleForm.title_cn"></el-input>
      </el-form-item>
      <el-form-item label="标题(英文)" prop="title_us">
        <el-input v-model="ruleForm.title_us"></el-input>
      </el-form-item>
      
      <el-form-item label="内容文本(中文)" prop="text_cn">
        <el-input type="textarea" v-model="ruleForm.text_cn" :autosize="{ minRows: 6, maxRows: 4}"></el-input>
      </el-form-item>
      <el-form-item label="内容文本(英文)" prop="text_us">
        <el-input type="textarea" v-model="ruleForm.text_us" :autosize="{ minRows: 6, maxRows: 4}"></el-input>
      </el-form-item>

      <el-form-item label="按钮文字(中文)" prop="button_text_cn">
        <el-input v-model="ruleForm.button_text_cn"></el-input>
      </el-form-item>
      <el-form-item label="按钮文字(英文)" prop="button_text_us">
        <el-input v-model="ruleForm.button_text_us"></el-input>
      </el-form-item>

      <el-form-item label="按钮跳转路径" prop="route">
        <el-input v-model="ruleForm.route"></el-input>
      </el-form-item>

      <el-form-item label="分页器文字(中文)" prop="pagination_text_cn">
        <el-input v-model="ruleForm.pagination_text_cn"></el-input>
      </el-form-item>
      <el-form-item label="分页器文字(英文)" prop="pagination_text_us">
        <el-input v-model="ruleForm.pagination_text_us"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>
<script>
import { editSlideshow, addSlideshow } from '@/api/slideshow/slideshow'
import imageUpload from '@/components/imageupload/image-upload.vue'
export default {
  components: {
    imageUpload
  },
  data() {
    return {
      ruleForm: {
        id: null,
        title_cn: '',
        title_us: '',
        text_cn: '',
        text_us: '',
        button_text_cn: '',
        button_text_us: '',
        route: '',
        param: '',
        pagination_text_cn: '',
        pagination_text_us: '',
        image_path: '',
      },
      rules: {
          image_path: [
            { required: true, message: '请上传封面', trigger: 'blur' },
          ],
          title_cn: [
            { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
          ],
          title_us: [
            { min: 1, max: 150, message: '长度在 1 到 150 个字符', trigger: 'blur' },
          ],
          text_cn: [
            { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' },
          ],
          text_us: [
            { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' },
          ],
          button_text_cn: [
            { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          button_text_us: [
            { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          route: [
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' },
          ],
          pagination_text_cn: [
            { required: true, message: '请输入分页器文字(中文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' },
          ],
          pagination_text_us: [
            { required: true, message: '请输入分页器文字(英文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' },
          ],
      },
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = {
        id: null,
        title_cn: '',
        title_us: '',
        text_cn: '',
        text_us: '',
        button_text_cn: '',
        button_text_us: '',
        route: '',
        param: '',
        pagination_text_cn: '',
        pagination_text_us: '',
        image_path: '',
      }
      this.$emit('close')
    },
    openDrawer () {
      console.log(this.params);
      if (this.params.data) {
        this.ruleForm = this.params.data
      }
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = this.params.data ? await editSlideshow(this.ruleForm) : await addSlideshow(this.ruleForm)
        if (result.success) {
          this.$message.success('已完成')
          this.close()
        }
      })
    },
  },
  computed: {
  },
}
</script>
<style scoped lang="less">
  .el-form {
    margin: 10px;
  }
</style>
