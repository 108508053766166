<template>
  <div>
    <el-dialog :title="'轮播图ID:' + params.slideshow_id"  :visible.sync="params.show" :before-close="close" size="60%" :with-header="false">
      <el-row>
        <el-col :span="24">
          <el-button class="tab-add-button" type="primary" @click="addClick">添加关联</el-button>
        </el-col>
      </el-row>
      <table-ui
        :propList="propList"
        :operationProps="operationProps"
        :listData="params.view"
        v-bind="otherProps"
      >
        <template #operation="scope">
          <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
            <span slot="reference" style="color: red;cursor: pointer;">删除</span>
          </el-popconfirm>
        </template>
      </table-ui>
    </el-dialog>
    <el-drawer :visible.sync="drawerShow" @close="closeDrawer"  :before-close="refreshClick" size="30%" :modal="false" :with-header="false" @open="openDrawer">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
        <el-form-item label="跳转路径" prop="route">
          <el-select clearable v-model="ruleForm.route" placeholder="请选择路由">
            <el-option-group
              v-for="(group, index) in clientRouteList"
              :key="index"
              :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="drawerShow = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import { addSlideshowWeb, deleteSlideshowWeb } from '@/api/slideshow/slideshow'
import { propList, operationProps, otherProps } from '../config/slideshowRouteConfig.js'
import TableUi from '@/components/table-ui/table-ui.vue'
import clientRouteList from '@/utils/clientRouteList'
export default {
  components: {
    TableUi
  },
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      drawerShow: false,
      clientRouteList,
      ruleForm: {
        slideshow_id: null,
        route: ''
      },
      rules: {
        route: [
          { required: true, message: '请选择页面', trigger: 'blur' }
        ],
      }
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    openDrawer () {
      this.ruleForm.slideshow_id = this.params.slideshow_id
    },
    closeDrawer () {
      this.$refs.ruleFormRef.resetFields()
    },
    // 刷新
    refreshClick () {
      this.$emit('refresh')
    },
    addClick () {
      this.drawerShow = true
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = await addSlideshowWeb(this.ruleForm)
        if (result.success) {
          this.$message.success('已完成')
          this.drawerShow = false
          this.refreshClick()
        }
      })
    },
    // 删除
    async deleteClick(id) {
      const result = await deleteSlideshowWeb(id)
      if (result.success) {
        this.$message.success('删除成功')
        this.refreshClick()
      }
    }
  },
  computed: {
    
  },
}
</script>
<style scoped lang="less">

</style>
